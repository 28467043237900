import { FunctionComponent } from 'react';
import { Grid } from '@mui/material';
import styles from './CleanSelection.module.scss';
import { BookNowButton, Hidden, ImageComponent } from '../../../Components';
import CheckListText from '../../CheckListText/CheckListText';
import { md } from '../../../../Assets/Styles/constants';

interface CleanSelectionProps {
  headerText: string;
  subHeaderText: string;
  imageSrc: string;
  imagePath: string;
  isDisableChecklist?: boolean;
  dropShadow?: boolean;
}

const CleanSelection: FunctionComponent<CleanSelectionProps> = ({
  headerText,
  subHeaderText,
  imageSrc,
  imagePath,
  isDisableChecklist,
}: CleanSelectionProps) => {
  return (
    <div className={styles.cleansection}>
      <Grid container item xs={12} sm={12} md={10} lg={10} direction="column" className={styles.allDropdownsContainer}>
        <Grid container className={styles.row}>
          <Grid item xs={12} sm={6} md={6} lg={7} className={styles.text}>
            <div className={styles.textContainer}>
              <h1 className={styles.headerText}>{headerText}</h1>
              <h2 className={styles.subHeaderText}>{subHeaderText}</h2>
            </div>
            <BookNowButton />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={5} className={styles.imgContainer}>
            {imageSrc ||
              (imagePath && (
                <ImageComponent isHighPriority source={imageSrc} path={imagePath} altText="people cleaning" />
              ))}
          </Grid>
        </Grid>
        <>
          {!isDisableChecklist && (
            <Hidden screenWidthToShow={md}>
              <CheckListText />
            </Hidden>
          )}
        </>
      </Grid>
    </div>
  );
};

export default CleanSelection;
