export const moveOutImagePath = `https://storage.googleapis.com/cheetah-cleaners-api.appspot.com/public/beforeAfter/moveOut`;

export const enableBeforeAfterPictures = true;
export const showInfoTopBanner = true;

export const MONTHS_TO_DISPLAY = 3;
export const LATITUDE = 47.6459;
export const LONGTITUDE = -122.3995;
export const RADIUS = 2;

export const isProduction = process.env.NODE_ENV === 'production';
export const isDevelopment = process.env.NODE_ENV === 'development';
export const isStaging = process.env.NODE_ENV === 'staging';

export const eventsKeyFormat = 'YYYY-MM-D';
export const timeSlotFormat = 'h:mm A';
export const dateAndTimeSlotFormat = `${eventsKeyFormat} ${timeSlotFormat}`;
export const defaultTimeZone = 'America/Los_Angeles';

const region = 'us-west1';

const staging = `https://${region}-cheetah-api.cloudfunctions.net/app`;
const dev = `http://localhost:5001/cheetah-api/${region}/app`;
const prod = `https://${region}-cheetah-cleaners-api.cloudfunctions.net/app`;

const useLocalhost = true;
const useStagingForDev = false;

const base = isProduction ? prod : isDevelopment ? (useLocalhost ? dev : useStagingForDev ? staging : '') : staging;
export const clientBaseUrl = isProduction
  ? 'https://cheetahcleaners.com'
  : isDevelopment
    ? 'http://localhost:3000'
    : 'https://cheetah-cleaners-api--preprod-9d21sads.web.app';
export const baseUrl = `${base}/api/v1`;

export const enableLogin = isDevelopment || isStaging;
export const enableBookWithKey = true; // isDevelopment || isStaging;

export const phoneFormatted = '(206) 486-0038';
export const contactPhone = '+12064860038';
export const phoneWithExtension = `+1 ${phoneFormatted}`;
export const inforEmail = 'info@cheetahcleaners.com';

export const imageSourceBaseUrl = 'https://media.cheetahcleaners.com';

export const shouldAutoPlayReviews = 'true';
export const isShowBookingform = true; // TODO remove

export const shouldSeparateRecurring = false;
