import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface RedirectToPathProps {
  children: ReactNode;
  path: string;
  conditionFunc: () => boolean;
  isLoading: boolean;
}

const RedirectToPath: FunctionComponent<RedirectToPathProps> = ({
  children,
  path,
  conditionFunc,
  isLoading,
}: RedirectToPathProps) => {
  const navigate = useNavigate();
  const [shouldLoadChildres, setShouldLoadChildren] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      if (conditionFunc()) {
        setShouldLoadChildren(true);
      } else {
        navigate(path, { replace: true });
      }
    }
  }, [isLoading]);

  return <>{shouldLoadChildres ? children : null}</>;
};

export default RedirectToPath;
