import { FunctionComponent, useEffect, useRef } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import {
  BookPage,
  Contact,
  Faq,
  SuccessfulPayment,
  NotFound,
  Terms,
  PrivacyPolicy,
  RecurringClean,
  SuccessfulRecurringBook,
  CancellationFees,
  DeepClean,
  MoveOutClean,
  RecurringCleanInfo,
  ServingAreas,
  SuccessResume,
  LoginPage,
  Blog,
  BlogPost,
  HelpSignIn,
  // SignUp,
  SelectedCleaning,
  Account,
  CustomerPortal,
  Home,
  BannerAlert,
  RedirectToExternal,
  BeforeBookingLeadPage,
} from './Client/Pages/lazyImports';
import { HomePage, LandingPage } from './Client/Pages';
import { Footer, Header } from './Client/Containers';
import styles from './App.module.scss';
import { enableLogin, isShowBookingform } from './Config';
import { careersLink } from './Assets/Data/NavigationLinks';
import RedirectToPath from './Client/Components/RedirectToPath';
import PaymentFormDataManager from './Mobx/BookingFlowData/PaymentFormDataManager';
import { useCartData } from './Client/CustomHooks/useCartData';

const App: FunctionComponent = () => {
  const location = useLocation();
  const allDataRef = useRef<HTMLDivElement>(null);
  const { isLoading } = useCartData();

  useEffect(() => {
    window.scrollToTop();
  }, [location.pathname]);

  return (
    <div className={styles.allData} ref={allDataRef}>
      <Header />
      <div className={styles.content}>
        <div className={styles.underHeader}>
          <BannerAlert />

          <Routes>
            {enableLogin && (
              <>
                <Route path="/portal" element={<CustomerPortal />}>
                  <Route path="home" element={<Home />} />
                  <Route path="account" element={<Account />} />
                  <Route path="cleanings/:cleaningId" element={<SelectedCleaning />} />
                  <Route index element={<Home />} />
                </Route>
                <Route path="/helpsigningin" element={<HelpSignIn />} />
                {/* <Route path="/signup" element={<SignUp />} /> */}
                <Route path="/login" element={<LoginPage />} />
              </>
            )}
            {isShowBookingform && <Route path="/bookclean-info" element={<BeforeBookingLeadPage />} />}
            <Route path="/home" element={<HomePage />} />
            <Route path="/faq" element={<Faq />} />
            {/* <Route  path="/what-we-clean" element={Services} /> */}
            <Route path="/contact" element={<Contact />} />
            <Route path="/careers" element={<RedirectToExternal link={careersLink} contentRef={allDataRef} />} />
            <Route
              path="/bookclean"
              element={
                isShowBookingform ? (
                  <RedirectToPath
                    path="/bookclean-info"
                    isLoading={isLoading}
                    conditionFunc={PaymentFormDataManager.isBookingFormInfoEntered}>
                    <BookPage />
                  </RedirectToPath>
                ) : (
                  <BookPage />
                )
              }
            />
            <Route path="/blog">
              <Route index element={<Blog />} />
              <Route path=":postId" element={<BlogPost />} />
            </Route>
            <Route path="/booked" element={<SuccessfulPayment />} />
            <Route path="/submitted-resume" element={<SuccessResume />} />
            <Route path="/booked-recurring" element={<SuccessfulRecurringBook />} />
            <Route path="/recurringClean" element={<RecurringClean />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/serving-areas" element={<ServingAreas />} />
            <Route path="/recurring-clean" element={<RecurringCleanInfo />} />
            <Route path="/move-out-clean" element={<MoveOutClean />} />
            <Route path="/deep-clean" element={<DeepClean />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cancellation" element={<CancellationFees />} />
            <Route path="/notFound" element={<NotFound />} />
            <Route path="/landing" element={<LandingPage />} />
            <Route path="/" element={<Navigate to="/home" replace />} />
            <Route element={<NotFound />} />
          </Routes>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default App;
